import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { ModCardThumbs, ModCardList, ModCardLarge, ModForm } from '.'

import MediaManager from 'components/media/MediaManager'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative'
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  edit: {
    position: 'absolute',
    top: '.5em',
    right: '.5em',
    zIndex: '10'
  },
  editButton: {
    margin: theme.spacing(0, 0, 0, .5),
    padding: theme.spacing(.5),
    minWidth: 'auto'
  }
}));  

export default function ModCard({mod, view, isAuthOwned}) {
  const classes = useStyles()
  let card = ''

  const [ editable, setEditable ] = useState(mod)

  let editButton = isAuthOwned ?
    <div className={classes.edit}>
      <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setEditable({ ...editable, editing: true, actionsOpen: null })}>
        <EditOutlinedIcon />
      </Button>
      <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setEditable({ ...editable, managingMedia: true, actionsOpen: null })}>
        <AddPhotoAlternateOutlinedIcon />
      </Button>

      <ModForm
        mod={editable} 
        open={editable.editing} 
        onSave={(rsp) => setEditable(Object.assign({}, editable , rsp, { editing: false })) }
        closeFn={() => setEditable({ ...editable, editing: false})}
      />

      <MediaManager 
        open={editable.managingMedia}
        mediaItems={editable.media || []}
        saveProps={{ mod: editable.id }}
        onMediaUpdate={(media) => setEditable({ ...editable, media })}
        closeFn={() => setEditable({ ...editable, managingMedia: false })}
      />

    </div>
  : null

  if (view === 'thumbs') {
    card = <ModCardThumbs mod={editable}>{editButton}</ModCardThumbs>
  } else if (view === 'list') {
    card = <ModCardList mod={editable}>{editButton}</ModCardList>
  } else if (view === 'large') {
    card = <ModCardLarge mod={editable}>{editButton}</ModCardLarge>
  }

  return card
}