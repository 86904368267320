import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import VideoChip from './VideoChip'
import ImageChip from './ImageChip'

const types = {
  IMAGE: 'App\\Models\\Image',
  VIDEO: 'App\\Models\\Video'
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 500,
    borderRadius: 0
  }
}));


export default function MediaChip({media, onClick}) {
  const classes = useStyles()
  
  let content = {
    card: ''
  }

  if (media.media_type === types.IMAGE) {
    content.card = <ImageChip image={media.media} onClick={onClick}/>
  } else if (media.media_type === types.VIDEO) {
    content.card = <VideoChip video={media.media}/>
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card className={classes.card}>
        {content.card}
      </Card>
    </Grid>
  )
}