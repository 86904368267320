import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';
import Button from '@material-ui/core/Button';
import { userService } from 'services/user/user.service'
import { carService } from 'services/car/car.service'
import CarEditors from 'components/car/CarEditors';
import CarLink from './CarLink'
import CarTag from './CarTag'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative'
  },
  media: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '76.25%', // not 16:9
  },
  cardContent: {
    paddingBottom: 0
  },
  edit: {
    position: 'absolute',
    top: '.5em',
    right: '.5em'
  },
  editButton: {
    margin: theme.spacing(0, 0, 0, .5),
    padding: theme.spacing(.5),
    minWidth: 'auto'
  }
}));

export default function CarCard(props) {
  const classes = useStyles();
  const [ car, setStateCar ] = useState(props.car);
  
  const setCar = (car) => {
    if (props.setCar) {
      props.setCar(car)
    }
    setStateCar(car)
  }
  const hideUserTag = props.hideUserTag || false

  const authOwned = (userId) => {
    const auth = userService.auth.user()
    return auth && auth.id === userId
  }

  const toggleFavorite = (car) => {
    if (!userService.auth.user()) {
      return window.location = '/register'
    }

    setStateCar({ ...car, favoriting: true })
    carService.toggleFavorite(car)
      .then((rsp) => {
        setCar({ ...car, auth_favorite_count: !car.auth_favorite_count, favoriting: false })
      })
  }


  return (
    <Card className={classes.card} square={true}>
      <CarLink car={car} doNotLink={props.doNotLink}>
        <CardMedia
          className={classes.media}
          image={car.profile_photo || '/img/defaults/car.jpg'}
          title={car.name}
          alt=""
        />
      </CarLink>
      <CardContent className={classes.cardContent}>
        <CarTag car={car} hideUserTag={hideUserTag} doNotLink={props.doNotLink}></CarTag>
      </CardContent>
      <CardActions disableSpacing>
        { !props.hideAuthLinks && authOwned(car.user_id) ?
          <div className={classes.edit}>
            <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setCar({ ...car, editing: true, actionsOpen: null })}>
              <EditOutlinedIcon />
            </Button>
            <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setCar({ ...car, managingMedia: true, actionsOpen: null })}>
              <AddPhotoAlternateOutlinedIcon />
            </Button>
            <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setCar({ ...car, deleting: true, actionsOpen: null })}>
              <DeleteOutlinedIcon />
            </Button>
            <Button variant="contained" className={classes.editButton} color="secondary" onClick={() => setCar({ ...car, pickingProfileMedia: true, actionsOpen: null })}>
              <PictureInPictureIcon />
            </Button>

            <CarEditors car={car} setCar={setCar}></CarEditors>
          </div>
        :
          <>
            <IconButton color="secondary" aria-label="add to favorites" onClick={() => setCar({ ...car, isUserFavorite: !car.isUserFavorite})}>
              { car.auth_favorite_count ? 
                <FavoriteIcon onClick={() => toggleFavorite(car)} /> 
              : 
                <FavoriteBorderOutlinedIcon onClick={() => toggleFavorite(car)}/> 
              }
            </IconButton>

            <IconButton color="secondary" aria-label="add to favorites">
              <ShareIcon />
            </IconButton>
          </>
        }
      </CardActions>
    </Card>
  )
}