import React from 'react'
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import MediaManager from 'components/media/MediaManager';
import CarForm from 'components/car/CarForm'

import UserPhotoPreviewSelector from 'scenes/users/components/UserPhotoPreviewSelector'

import { carService } from 'services/car/car.service'


export default function CarEditors({ car, setCar }) {
  return (
    <>
      { car.id ?
        <>
        <CarForm
          car={car} 
          open={car.editing} 
          onSave={(rsp) => setCar(Object.assign({}, car , rsp, { editing: false })) }
          closeFn={() => setCar({...car, editing: false})}
        />

        <MediaManager 
          open={car.managingMedia}
          mediaItems={car.media}
          saveProps={{ car: car.id }}
          onMediaUpdate={(media) => setCar({ ...car, media })}
          closeFn={() => setCar({ ...car, managingMedia: false })}
        />

        <Drawer 
          className="media-drawer" 
          anchor="bottom" 
          open={car.pickingProfileMedia} 
          ModalProps={{ onBackdropClick: () => setCar({...car, pickingProfileMedia: false}), onEscapeKeyDown: () => setCar({...car, pickingProfileMedia: false}) }}
        >
          <CloseIcon onClick={() => setCar({...car, pickingProfileMedia: false})} className="close-link"></CloseIcon>

          <UserPhotoPreviewSelector 
            profile={{name: 'profile_photo', src: car.profile_photo || '/img/defaults/profile-photo.png'}}
            cover={{name: 'cover_photo', src: car.cover_photo || '/img/defaults/cover-photo.jpg'}}
            title={car.name}
            mediaItems={car.media}
            updateFn={(data) => carService.saveMediaDefaults(car, data)}
            saveProps={{car: car.id}}
            finishFn={(data) => setCar(Object.assign({}, car , data)) }
            closeFn
          ></UserPhotoPreviewSelector>
        </Drawer>
      </>
      : null }
    </>
  );
}