import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: theme.spacing(0, 1)
  }
}));  

export default function ReadMore({text, maxWords}) {
  const classes = useStyles()

  const [ expanded, setExpanded ] = useState(false)
  const tokens = text.split(/\s+/)
  const tooBig = tokens.length > maxWords

  function toggleExpanded() {
    setExpanded(!expanded)
  }


  let content = text + ''
  let readMore = <Button className={classes.link} onClick={toggleExpanded}>{ !expanded ? 'Read more' : 'Read less' }</Button>

  if (tooBig && !expanded) {
    content = tokens.slice(0, maxWords).join(' ').replace(/<[^>]*>?/gm, '') + '... '
  }
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: content }}></span>
      { (tooBig ? readMore : '') }
    </>
  )
}