import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) =>
  createStyles({
    profileCard: {
      position: 'absolute',
      top: '-10em',
      height: '100%',
      left: 0,
      right: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        top: 0,
        marginTop: '-5em',
        marginBottom: '1em'
      },
    },
    media: {
      height: 0,
      // paddingTop: '56.25%', // 16:9
      paddingTop: '76.25%', // not 16:9
    },
  }),
);

/**
 * 
 * @param {*} param0 
 */
export default function StickyProfile({ profile, children }) {
  const classes = useStyles();
  
  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      spacing={1}
    >
      <Grid item xs={12} md={3} style={{position: 'relative'}}>
        <div className={clsx('garage-profile-header', classes.profileCard)}>
          <div className="sticky">
            { profile }
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={9}>
        { children }
      </Grid>
    </Grid>
  );
}