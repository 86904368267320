import { userConstants } from './user.constants';

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.REGISTER_SUCCESS:
      return { ...action.user, registering: true }
    case userConstants.REGISTER_FAILURE:
      return { registering: false };
    case userConstants.LOGIN_REQUEST:
      return { loggingIn: true }
    case userConstants.LOGIN_SUCCESS:
      return action.user
    case userConstants.LOGIN_FAILURE:
      return { loggingIn: false };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}