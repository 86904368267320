import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { AppToast } from './components';
import RecentCarsScene from './scenes/cars/RecentCarsScene'
import CarScene from './scenes/cars/CarScene'
import GarageScene from './scenes/cars/GarageScene'

import { RecentUsersScene, UserSettingsScene } from './scenes/users'
import { AboutScene, LoginScene, RegisterScene } from './scenes/home'

import ScrollToTop from 'helpers/ScrollToTop'

import './index.scss';
import 'react-image-lightbox/style.css';

import 'typeface-roboto';

let theme = createMuiTheme({
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  palette: {
    common: {
      white: '#ffffff'
    }
  }
});
theme = responsiveFontSizes(theme)

theme = responsiveFontSizes(theme);


const App = ({alert, user}) => {
  return (
    <Router>
      <ScrollToTop />
      <CssBaseline>
        <MuiPickersUtilsProvider utils={MomentUtils}>

          <ThemeProvider theme={theme}>
            <AppToast alert={alert}></AppToast>
            <div >
              <Switch>
                <Route path="/" exact component={RecentCarsScene} />
                <Route path="/about" exact component={AboutScene} />
                <Route path="/login" exact component={LoginScene} />
                <Route path="/register" exact component={RegisterScene} />
                <Route path="/users" component={RecentUsersScene} />
                <Route path="/cars" component={RecentCarsScene} />
                <Route path="/settings" component={UserSettingsScene} />
                <Route path="/:username/:car/:section" component={CarScene} />
                <Route path="/:username/:car" component={CarScene} />
                <Route path="/:username" component={GarageScene} />
              </Switch>
            </div>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </CssBaseline>
    </Router>
  )
}

const mapStateToProps = state => ({
  alert: state.alert,
  user: state.user
});

export default connect(mapStateToProps)(App);