import config from 'helpers/config'
import authHeader from 'helpers/authHeader'

const auth = {
  user: () => {
    const data = localStorage.getItem('user');
    return data ? JSON.parse(data) : null
  },

  is: (userId) => {
    const data = localStorage.getItem('user');
    const auth = data ? JSON.parse(data) : null
    return auth && auth.id === userId
  },

  set: (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  },

  token: () => {
    const data = localStorage.getItem('token');
    return data ? JSON.parse(data) : null
  }
}

export const userService = {
  // user authentication handling
  login,
  logout,
  register,
  auth,

  // generic api requests
  recent,
  get,

  // auth api requests
  
  getMedia,
  update,
  changePassword
};

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { email, password } })
  };

  return fetch(config.api('/login'), requestOptions)
    .then(handleResponse)
    .then(data => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.token);
      return data.user;
    });
}

function get(username) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  return fetch(config.api('/users/' + username), requestOptions)
    .then(handleResponse)
}


function recent(page) {
  return fetch(config.api('/users/newest?page=' + page))
    .then(handleResponse)
}


function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user })
  };

  return fetch(config.api('/signup'), requestOptions)
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.token);
      return data.user
    });
}


function getMedia(types) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  };

  let url = config.authApi('/media?')

  if (types) {
    url += '&types=' + types
  }

  return fetch(url, requestOptions)
    .then(handleResponse)
}

function update(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ user })
  };

  let url = config.authApi('/settings')

  return fetch(url, requestOptions)
    .then(handleResponse)
}

function changePassword(user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ user })
  };

  let url = config.authApi('/settings/password')

  return fetch(url, requestOptions)
    .then(handleResponse)
}





function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
