import React from 'react'
import { BannerHeader, HeroHeader, FixedHeader, MobileHeader, HeaderLinks } from '.'
import Hidden from '@material-ui/core/Hidden';
import './header.scss'

export default function AppHeader(props) {
  const children = props.children
  const type = props.type

  let header = null;

  if (type === 'fixed') {
    header = (
      <FixedHeader position="static" color="default" elevation={0}>
        <HeaderLinks></HeaderLinks>
      </FixedHeader>
    )
  } else if (type === 'hero') {
    header = (
      <HeroHeader position="static" color="default" elevation={0}>
        <HeaderLinks></HeaderLinks>
      </HeroHeader>
    )
  } else {
    header = (
      <BannerHeader position="static" color="default" elevation={0}>
        <HeaderLinks></HeaderLinks>
      </BannerHeader>
    )
  }

  const mobileHeader = (
    <MobileHeader position="static" color="default" elevation={0}>
      <HeaderLinks></HeaderLinks>
    </MobileHeader>
  )

  return (
    <div>
      <Hidden xsDown>
        { header } 
      </Hidden>

      <Hidden smUp>
        { mobileHeader } 
      </Hidden>

      { children }
    </div>
  )
}