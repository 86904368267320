import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from "@material-ui/pickers";
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios'

import authHeader from 'helpers/authHeader'
import config from 'helpers/config'

import OutlinedTextField from 'components/form/OutlinedTextField'
import LoadingSubmitButton from 'components/form/LoadingSubmitButton'
import Autocomplete from 'components/form/Autocomplete'
import FormError from 'components/form/FormError'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  closeLink: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer'
  },
  mediaGrid: {
    margin: theme.spacing(1, 0)
  },
  picker: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}))

function CarForm (props) {
  
  const open = props.open
  const closeFn = props.closeFn
  const onSave = props.onSave
  const classes = useStyles()
  
  // set state for media
  const [ car, setCar ] = useState(props.car)
  const [ make, setMake ] = useState(props.car.make ? props.car.make.name : '')
  const [ model, setModel ] = useState(props.car.model ? props.car.model.name : '')
  const [ trim, setTrim ] = useState(props.car.trim ? props.car.trim.name : '')
  const [ error, setError ] = useState('')

  const handleChange = (event) => {
    setError('')
    const { name, value } = event.target
    setCar( { ...car, [name]: value})
  }

  const handleMakeChange = (value) => {
    setMake(value)
    setCar({ ...car, make: value })
  }

  const handleModelChange = (value) => {
    setModel(value)
    setCar({ ...car, model: value })
  }

  const handleTrimChange = (value) => {
    setTrim(value)
    setCar({ ...car, trim: value })
  }

  const saveCar = (event, close) => {
    event.preventDefault()
    setCar({ ...car, saving: true })
    setError('')

    if (car.id) {
      axios.put(config.authApi('/cars/' + car.id), { car }, { 
        headers: authHeader()
      }).then(response => {
        setCar({ ...car, saving: false })
        onSave(response.data, true)
      }).catch(error => {
        setError(error.response.data.message)
        setCar({ ...car, saving: false })
      })
    
    } else {
      axios.post(config.authApi('/cars'), { car }, {
        headers: authHeader()
      }).then(response => {
        setCar({ ...car, saving: false })
        onSave({ ...response.data, managingMedia: true})
      }).catch(error => {
        setError(error.response.data.message)
        setCar({ ...car, saving: false })
      })
    }
    
  }

  return (
    <Drawer 
      className="media-drawer" 
      anchor="bottom" 
      open={open} 
      ModalProps={{ onBackdropClick: closeFn, onEscapeKeyDown: closeFn }}
    >
      <CloseIcon onClick={closeFn} className={classes.closeLink}></CloseIcon>
      
      <Typography component="h1" variant="h5" className={classes.header}>
        { car.id ? 'Edit Car Details' : 'Add a New Car' }
      </Typography>

      <form className={classes.form} noValidate onSubmit={saveCar}>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12} sm={12} md={8}>
            <OutlinedTextField required id="name" label="Car Name" value={car.name} onChange={handleChange} first={true} />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePicker
              className={classes.picker}
              inputVariant="outlined"
              variant="dialog"
              format="L"
              label="Date Bought"
              autoOk
              clearable={true}
              value={car.date_bought}
              onChange={(date_bought) => setCar({...car, date_bought: date_bought ? moment(date_bought).format('YYYY-MM-DD') : null})}
              animateYearScrolling
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePicker
              className={classes.picker}
              label="Date Sold"
              inputVariant="outlined"
              variant="dialog"
              format="L"
              autoOk
              clearable={true}
              value={car.date_sold}
              onChange={(date_sold) => setCar({...car, date_sold: date_sold ? moment(date_sold).format('YYYY-MM-DD') : null})}
              animateYearScrolling
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <OutlinedTextField required id="year" label="Year" value={car.year} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete 
              id="make" 
              label="Make" 
              value={make} 
              onChange={handleMakeChange}
              searchUrl={config.api('/car/makes')} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete 
              id="model" 
              label="Model" 
              value={model} 
              onChange={handleModelChange} 
              searchUrl={config.api('/car/models')}
              searchParams={{ make }}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete 
                id="trim" 
                label="Trim" 
                value={trim} 
                onChange={handleTrimChange} 
                searchUrl={config.api('/car/trims')}
                searchParams={{ make, model }}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedTextField id="description" label="Driver Review" value={car.description} multiline onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedTextField id="mod_roadmap" label="Mod Roadmap" placeholder="What do you want to do with this car? What mods have you been thinking about, what direction are you heading?" value={car.mod_roadmap} multiline onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <FormError error={error}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LoadingSubmitButton label="Save Car" className={classes.submit} onClick={(event) => saveCar(event)} loading={car.saving} />
          </Grid>
        </Grid>
      </form>
    </Drawer>
  )
}

export default CarForm