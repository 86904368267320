import React from 'react'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  chip: { backgroundColor: theme.palette.primary.main, color: 'white' }
}));

export default function ModCategoryChip({category, className}) {
  const classes = useStyles()
  const elemClass = className += ' ' + classes.chip
  return <Chip size="small" label={category.name} className={elemClass} />
}