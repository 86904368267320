import config from 'helpers/config'
import { userService } from 'services/user'
import authHeader from 'helpers/authHeader'

export const carService = {
  recent,
  get,
  saveMediaDefaults,
  toggleFavorite
};

function recent(page) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(config.api('/cars/newest?page=' + page), requestOptions)
    .then(handleResponse)
}

function get(username, car) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' }
  }

  return fetch(config.api('/cars/' + username + '/' + car), requestOptions)
    .then(handleResponse)
}

function saveMediaDefaults(car, data) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };

  let url = config.authApi('/cars/' + car.id + '/default-media')

  return fetch(url, requestOptions)
    .then(handleResponse)
}

function toggleFavorite(car) {
  const requestOptions = {
    method: car.auth_favorite_count ? 'DELETE' : 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ type: 'Car', id: car.id})
  };

  let url = config.authApi('/favorites')

  return fetch(url, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
