import React from 'react'
import { AppHeader } from 'components/header';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      padding: theme.spacing(0, 2)
    },
    loader: {
      padding: theme.spacing(2, 0),
    },
    img: {
      width: '100%',
      padding: theme.spacing(2, 0)
    }
  }),
);

function AboutScene() {
  const classes = useStyles();

  return (
    <AppHeader>
      <div className={classes.page}>
      <Typography component="h2" variant="h2" paragraph={true}>We're car people. We get it.</Typography>
      <Typography component="p" paragraph={true}>Do you like working on your car? Getting grease all over your hands or accidentally shocking yourself while installing some electronics? We know the drill. Are you on a forum? Does your signature include 3 lines describing the mods you've made to your car? We wish we had three whole lines, but again, we understand.</Typography>
      <Typography component="p" paragraph={true}>If you're here, you've likely poured a lot of heart and soul into your cars. We've seen some awesome mod journals across the web, and wanted to create a place for everyone to share all their hard work with the world. Show off what you've done, what you liked, what you didn't.</Typography>

      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography component="h4" variant="h4" paragraph={true}>Store all your cars</Typography>
          <img className={classes.img} src="/img/home/garage.jpg" alt=""/>
          <Typography component="p" paragraph={true}>Odds are, your current car isn't the only car you've ever modded. You might even have two or more at once that you're working on. Create your virtual garage of cars on MyCarMods. Each of your cars gets its own spot and its own page. Regardless of whether you want to share mods you've done on cars in your past, show off mods on your current ride, or plan upcoming mods, you can do it all here.</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="h4" variant="h4" paragraph={true}>Easily Manage your Garage</Typography>
          <img className={classes.img} src="/img/home/mods-large.jpg" alt=""/>
          <Typography component="p" paragraph={true}>Each car not only gets its own spot in your garage, it gets its own page to be shown off to the world. Each of your cars has its own place to show off your mods, info about your car, and any images or videos you might have. A clean cut and stylish design helps show off the important stuff.</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography component="h4" variant="h4" paragraph={true}>Browse Other Enthusiast Work</Typography>
          <img className={classes.img} src="/img/home/others.jpg" alt=""/>
          <Typography component="p" paragraph={true}>The goal of this site is to put the power to show off your hard work. It shouldn't be hard to do so. That was the guiding principle. Your garage is designed intuitively, to make it as easy as possible to add all of your cars and add any mods you've made.</Typography>
        </Grid>
      </Grid>
      </div>
    </AppHeader>
  )
}

export default AboutScene