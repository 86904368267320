function api(path) {
  return process.env['REACT_APP_API_BASE'] + path
}

function authApi(path) {
  return process.env['REACT_APP_API_BASE'] + '/auth' + path
}


export default {
  api,
  authApi
}