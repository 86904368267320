import React from 'react';
import TextField from '@material-ui/core/TextField';

function makeElement(props) {
  if (props.first) {
    return (
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id={props.id}
        label={props.label}
        name={props.id}
        type="password"
        onChange={props.onChange}
        autoFocus />
    );
  } else {
    return (
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id={props.id}
        onChange={props.onChange}
        label={props.label}
        type="password"
        name={props.id} />
    );
  }
}
export default function PasswordField(props) {
  return makeElement(props);
}