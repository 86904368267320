import React from 'react'
import Chip from '@material-ui/core/Chip';

export default function ModPowerGainChip({gain, className}) {
  if (!gain) {
    return null
  }

  if (gain > 0) {
    const label = '+' + gain + ' hp'
    return <Chip size="small" label={label} className={className} />
  }

  const label = '-' + gain + ' hp'
  return <Chip size="small" label={label} className={className} />
}