import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative'
  },
  autocomplete: {
    position: 'absolute',
    top: '80%',
    left: '0',
    width: '100%',
    backgroundColor: '#fff',
    border: '2px solid #303f9f',
    zIndex: 10,
    borderTop: 0,
    borderRadius: '5px',
  },
  autocompleteItem: {
    borderTop: '1px solid #efefef',
    padding: theme.spacing(1, 2),
    cursor: 'pointer'
  }
}))

export default function Autocomplete(props) {
  const classes = useStyles()

  const [ searchParams, setSearchParams ] = useState(props.searchParams)
  const [ results, setResults ] = useState([])
  const [ showResults, setShowResults ] = useState(false)
  const [ searchTerm, setSearchTerm ] = useState(props.value)
  const [ selectedIndex, setSelectedIndex ] = useState(-1)

  React.useEffect(() => {
    if (JSON.stringify(props.searchParams) !== JSON.stringify(searchParams)) {
      setResults([])
      setSearchParams(props.searchParams)
    }
    
  }, [ props.searchParams, searchParams ]);

  // handle arrow and enter/space keystrokes
  const handleKeyDown = (e) => {
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && selectedIndex > -1) {
      e.preventDefault()
      setSelectedIndex(selectedIndex - 1)
    } else if (e.keyCode === 40 && selectedIndex < filterResults().length - 1) {
      e.preventDefault()
      setSelectedIndex(selectedIndex + 1)
    } else if ((e.keyCode === 13 || e.keyCode === 32) && selectedIndex > -1) {
      e.preventDefault()
      selectOption(selectedIndex)
    }
  }

  // handler for selecting an option
  const selectOption = (index) => {
    setShowResults(false)
    changeValue(filterResults()[index].name)
  }

  // onChange method for the input field
  const onChange = (event) => {
    changeValue(event.target.value)
    setShowResults(filterResults().length)

    if (!event.target.value || results.length) {
      return
    }

    fetchItems(event.target.value)
  }

  // called when selecting an option
  const changeValue = (value) => {
    setSearchTerm(value)
    setSelectedIndex(-1)

    if (props.onChange) {
      props.onChange(value)
    }
  }

  // hide the results when the field is blurred
  const onBlur = (event) => {
    setTimeout(() => {
      setShowResults(false);
    }, 500);
  }

  // just refresh the item
  const onFocus = (event) => {
    fetchItems()
    setShowResults(true);
  }

  const filterResults = () => {
    return results.filter(
      item =>
        !searchTerm ||
        item.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    )
    .slice(0, 10)
  }


  // method to fetch results
  const fetchItems = (query) => {
    const url = props.searchUrl
    axios.get(url, { params: props.searchParams}).then(response => {
      setResults(response.data)
    })
  }

  return (
    <div className={classes.wrapper}>
      <TextField
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        value={props.value}
        variant="outlined"
        margin="normal"
        fullWidth
        multiline={props.multiline}
        id={props.id}
        label={props.label}
        name={props.id}
        onChange={onChange}
        autoFocus={props.autofocus} />
      
        { showResults && results.length ? (
          <div className={classes.autocomplete}>
            {filterResults()
              .map((item, index) => (
                <div
                  onClick={() => selectOption(index)}
                  key={item.id}
                  className={classes.autocompleteItem}
                  style={{
                    backgroundColor: selectedIndex === index ? "lightgray" : "white",
                  }}
                >
                  {item.name}
                </div>
              ))}
          </div>
        ) : null }
    </div>
  )
}