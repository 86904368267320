import React from 'react';
import TextField from '@material-ui/core/TextField';

function makeElement(props) {
  return (
    <TextField
      value={props.value}
      variant="outlined"
      margin="normal"
      fullWidth
      required={props.required}
      multiline={props.multiline}
      placeholder={props.placeholder}
      id={props.id}
      label={props.label}
      name={props.id}
      onChange={props.onChange}
      autoFocus={props.autoFocus}
      className={props.className}
      inputProps={{
        style: {
          ...props.style,
          height: props.multiline ? '10em' : 'auto'
        }
      }}
    />
  );
}

function OutlinedTextField(props) {
  return makeElement(props);
}

export default OutlinedTextField