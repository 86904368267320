import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
}))

export default function FormError(props) {
  const classes = useStyles()
  
  return (
    props.error ? <div className={classes.error}>{props.error}</div> : null
  )

}