import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

import ReadMore from 'components/util/ReadMore'

import ModCategoryChip from './ModCategoryChip'
import ModRatingChip from './ModRatingChip'
import ModCostChip from './ModCostChip'
import ModInstallTimeChip from './ModInstallTimeChip'
import ModPowerGainChip from './ModPowerGainChip'
import ModWeightSavingsChip from './ModWeightSavingsChip'
import ModInstallationDateChip from './ModInstallationDateChip'

import MediaCarousel from 'components/util/MediaCarousel'


const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: 0,
    minHeight: '650px',
    position: 'relative'
  },
  media: {
    height: '100%',
    paddingTop: '50%', // not 16:9
    position: 'relative'
  },
  mediaCarousel: {
    position: 'absolute',
    top: 0,
    height: '100%',
    left: 0,
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    borderRadius: '4px !important'
  },
  link: {
    cursor: 'pointer'
  }
}));

export default function ModCardLarge({ mod, children }) {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <Card square={true} className={classes.card}>
        <div className={classes.media}>
          <MediaCarousel media={mod.media} className={classes.mediaCarousel}></MediaCarousel>
        </div>

        <CardContent>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            {mod.title}
          </Typography>

          <ModCategoryChip size="small" category={mod.category} className={classes.chip} />
          <ModInstallationDateChip size="small" installDate={mod.install_date} removalDate={mod.removal_date} className={classes.chip} />
          <ModRatingChip size="small" rating={mod.happiness} className={classes.chip} />
          <ModCostChip size="small" cost={mod.price} className={classes.chip} />
          <ModInstallTimeChip size="small" min={mod.install_time} className={classes.chip} />
          <ModPowerGainChip size="small" gain={mod.hp_gains} className={classes.chip} />
          <ModWeightSavingsChip size="small" savings={mod.weight_savings} className={classes.chip} />

          <Typography variant="body1" component="div" className={classes.header}>
            <ReadMore text={mod.description } maxWords={50} />
            { children }
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}