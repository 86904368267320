export const prefsService = {
  get,
  set
};

function get(key) {
  let prefs = localStorage.getItem('prefs')

  if (!prefs) {
    localStorage.setItem('prefs', JSON.stringify({}));
    prefs = localStorage.getItem('prefs')
  }

  prefs = JSON.parse(prefs)
  return prefs[key] || null;
}

function set(key, value) {
  let prefs = localStorage.getItem('prefs')

  if (!prefs) {
    prefs = {}
  } else {
    prefs = JSON.parse(prefs)
  }

  prefs[key] = value
  localStorage.setItem('prefs', JSON.stringify(prefs));
}