import React from 'react'
import AppBar from '@material-ui/core/AppBar';

export default function FixedHeader({ children }) {
  return (
    <div>
      <AppBar position="fixed" color="default" elevation={0} className="fixed-header">
        {children}
      </AppBar>
    </div>
  )
}