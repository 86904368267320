import React from 'react'
import Chip from '@material-ui/core/Chip';
import moment from 'moment'

export default function ModInstallationDateChip({installDate, removalDate, className}) {
  const labels = []
  if (installDate) {
    labels.push('Installed ' + moment(installDate).format('L'))
  }

  if (removalDate) {
    labels.push('Removed ' + moment(removalDate).format('L'))
  }

  if (labels.length) {
    return <Chip size="small" label={labels.join(', ')} className={className} />
  }

  return null
}