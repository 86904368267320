import React from 'react'
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none'
    }
  }),
);

export default function CarLink({car, className, color, section, children, doNotLink}) {  
  const classes = useStyles()

  if (doNotLink) {
    return children
  }

  let link = '/' + car.user.username
  
  if (car.slug) {
    link += '/' + car.slug
  } else {
    link += '/' + car.id
  }

  if (section) {
    link += '/' + section
  }
  
  return (
    <Link to={link} className={clsx(className, classes.link)}>
      { children }
    </Link>
  )
}