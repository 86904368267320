import React from 'react'
import Chip from '@material-ui/core/Chip';

export default function ModInstallTimeChip({min, className}) {
  if (!min) {
    return ''
  }

  let display = ''
  if ( min < 60 ) {
    display = min + 'min install time'
  } else {
    const hours = Math.floor(min / 60)
    const minutes = min % 60

    display = hours + 'h' 
    display += (minutes ? minutes + 'min' : '')
    display += ' install time'
  }

  return <Chip size="small" label={display} className={className} />
}