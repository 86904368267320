import React from 'react'
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button'

import axios from 'axios'

import config from 'helpers/config'
import authHeader from 'helpers/authHeader'
import ImageChip from 'components/media/ImageChip'
import VideoChip from 'components/media/VideoChip'
import MediaUploader from 'components/media/MediaUploader'

import './media.css'

export default class MediaManager extends React.Component {
  classes = {}
  isManager = !this.props.onSelect
  isSelector = !this.isManager

  state = {
    media: this.props.mediaItems ? this.props.mediaItems : [],
    fetched: this.props.mediaItems ? true : false
  }

  setMedia = (media) => this.setState({ media })
  setFetched = (fetched) => this.setState({fetched})
  getMedia = () => this.state.media
  getFetched = () => this.state.fetched

  selectMedia = (item) => {
    if (this.props.onSelect) {
      this.props.onSelect(item)
      this.props.closeFn()
    }

    if (this.props.onMediaUpdate) {
      this.props.onMediaUpdate(this.getMedia())
    }
  }

  onMediaAdded = (added) => {
    this.setMedia([ added, ...this.getMedia()])
    this.selectMedia(added)
  }

  setDeleting = (id) => {
    this.setMedia(this.getMedia().map((item) => (item.id === id ? { ...item, deleting: true } : item)))
  }

  deleteMedia = (id) => {
    this.setDeleting(id, true)

    axios.delete(config.authApi('/media/' + id), { 
      headers: authHeader()
    }).then(response => {
      
      this.setDeleting(id, false)
      this.setMedia(this.getMedia().map((item) => (item.id === id ? response.data : item)))

      if (this.props.onMediaUpdate) {
        this.props.onMediaUpdate(this.getMedia())
      }
    })
  }

  callActionFunction = (fn, src) => {
    fn(src)
  }

  isImage = (media) => media.media_type === 'App\\Models\\Image'
  isVideo = (media) => media.media_type === 'App\\Models\\Video'

  buildMediaList = () => {
    const fetched = this.getFetched()
    const media = this.getMedia()

    if (!media.length && !fetched) {
      return <div key="loader1"><LinearProgress /></div>;
    } else if (!media.length || !media.filter((item) => !item.deleted_at).length) {
      return (
        <Typography variant="h5" className="no-media-yet">
          No media yet! Use the controls above to add images or videos!
        </Typography>
      )
    }

    const mediaListItems = this.getMedia() ? this.getMedia()
      .filter((item) => !item.deleted_at)
      .map((item) => {
      return (
        <Grid item xs={6} sm={4} md={3} key={item.id}>
          { this.isImage(item) ? <ImageChip image={item.media} onClick={!this.isManager ? () => this.selectMedia(item) : null}></ImageChip> : null }
          { this.isVideo(item) ? <VideoChip video={item.media}></VideoChip> : null }

          { this.isManager ?
              <Button disabled={item.deleting} onClick={() => this.deleteMedia(item.id)} color="secondary">
                { item.deleting ? 'Deleting...' : 'Delete' }
              </Button>
            : null }
        </Grid>
      )
    }) : null
  
    return (
      <Grid direction="row" container justify="flex-start" spacing={1} className="media-grid">{ mediaListItems }</Grid>
    )
  }

  componentDidMount = () => {
    if (!this.props.fetchMedia || this.getFetched()) {
      return
    }

    this.setFetched(true)
    this.props.fetchMedia()
      .then((data) => {
        this.setMedia(data) 
        this.setFetched(true)
      })
  }

  render = () => {
    const mediaList = this.buildMediaList()

    return  (
      <Drawer 
        className="media-drawer" 
        anchor="bottom" 
        open={this.props.open} 
        ModalProps={{ onBackdropClick: this.props.closeFn, onEscapeKeyDown: this.props.closeFn }}
      >
        <CloseIcon onClick={this.props.closeFn} className="close-link"></CloseIcon>

        <MediaUploader onSuccess={this.onMediaAdded} imagesOnly={this.isSelector} saveProps={this.props.saveProps} />

        <Divider></Divider>

        { mediaList }
      </Drawer>
      )
  }

}