import React from 'react'

export default function HeroHeader({ children }) {
  React.useEffect(() => {
    headerColorChange()
    window.addEventListener("scroll", headerColorChange);
    
    return function cleanup() {
        window.removeEventListener("scroll", headerColorChange);
    };
  });

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;

    if (windowsScrollTop > 100) {
      const header = document.getElementById("hero-header")
      header.classList.add('scrolled');
    } else {
      const header = document.getElementById("hero-header")
      header.classList.remove('scrolled')
    }
  };

  return (
    <div>
      <div id="hero-header" className="header hero-header">
        <div className="header-shadow"></div>
        {children}
      </div>
    </div>
  )
}