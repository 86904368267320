import React from 'react'
import Typography from '@material-ui/core/Typography';

export default function CarAbout(props) {
  const car = props.car
  const content = {
    review: '',
    roadmap: ''
  }

  if (car.description) {
    content.review = (
      <div>
        <Typography variant="h5" component="h3" paragraph={true}>
          Driver Review
        </Typography>
        <Typography variant="body1" component="div" paragraph={true}>
          <div dangerouslySetInnerHTML={{ __html: car.description }}></div>
        </Typography>
      </div>
    )
  }

  if (car.mod_roadmap) {
    content.roadmap = (
      <div>
        <Typography variant="h5" component="h3" paragraph={true}>
          Owner Roadmap
        </Typography>
        <Typography variant="body1" component="div" paragraph={true}>
          <div dangerouslySetInnerHTML={{ __html: car.mod_roadmap }}></div>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      {content.review}
      {content.roadmap}
    </div>
  )
}