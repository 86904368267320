import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import OutlinedTextField from './OutlinedTextField'
import LoadingSubmitButton from './LoadingSubmitButton'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative'
  },
  button: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(1),
    width: theme.spacing(12),
  }
}))

export default function TextFieldWithButton({ id, buttonLabel, label, value, loading, onChange, onSubmit, className }) {
  const classes = useStyles();

  const cancelledSubmit = (event) => {
    event.preventDefault()
    onSubmit(event)
  }
  return (
    <>
      <div className={classes.wrapper} >
        <form onSubmit={cancelledSubmit}>
          <OutlinedTextField style={{paddingRight: '114px'}} id={id} label={label} value={value} onChange={onChange} className={className} />
          <LoadingSubmitButton className={classes.button} label={buttonLabel || label} onClick={onSubmit} loading={loading} />
        </form>
      </div>
    </>
  )
}