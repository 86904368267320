import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import VideoChip from 'components/media/VideoChip'
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles(theme => ({
  carouselCard: {
    backgroundSize: 'cover',
    width: '100%',
    height: '100%'
  }
}))



export default function MediaCarousel({ media, className }) {
  const classes = useStyles()

  let showControls = false  
  let elements = (
    <div>
      
    </div>
  )
  
  if (media && media.length) {
    showControls = true
    elements = media
      .filter((item) => !item.deleted_at)
      .map((media, index) => {
        // return a video player
        if (media.media_type === "App\\Models\\Video") {
          return (
            <div key={media.id}>
              <VideoChip video={media.media} standalone={true} />
            </div>
          )
        }

        const image = media.media
        return (
          <div key={media.id} className={classes.carouselCard} style={{minHeight: '250px', backgroundImage: 'url('+image.src+')'}}></div>
        )
    })
  }

  if (media)
    return (
      <Carousel 
        infiniteLoop
        showStatus={false} 
        showThumbs={false}
        showArrows={showControls} 
        showIndicators={showControls}
        className={className}
      >
        { elements }
      </Carousel>
    )

  return null
}