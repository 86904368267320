import React from 'react'

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    background: 'none'
  }
}));

export default function BannerHeader({ user, logout, history, children }) {
  const classes = useStyles();

  return (
    <div>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        {children}
      </AppBar>
    </div>
  )
}