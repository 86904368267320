import React from 'react'
import Chip from '@material-ui/core/Chip';
import { red, yellow, blue, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  great: { backgroundColor: green[500], color: theme.palette.common.white },
  good: { backgroundColor: blue[500], color: theme.palette.common.white },
  meh: { backgroundColor: yellow[500] },
  bad: { backgroundColor: red[500] },
}));

export default function ModRatingChip({ rating, className }) {
  const classes = useStyles()

  if (typeof rating !== 'number') {
    return ''
  }

  let colorClass = '';

  if (rating >= 8) {
    colorClass = classes.great
  } else if (rating >= 6) {
    colorClass = classes.good
  } else if( rating >= 3) {
    colorClass = classes.meh
  } else {
    colorClass = classes.bad
  }

  const elemClass = className += ' ' + colorClass

  const label = rating + '/10 stars'
  return <Chip size="small" label={label} className={elemClass} />
}