import React from 'react'
import moment from 'moment'
import CarLink from './CarLink'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  carLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.4em',
    lineHeight: 1
  },
  userLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    lineHeight: 1
  },
  description: {
    margin: 0,
    fontSize: '1em'
  },
  carName: {
    fontStyle: 'italic',
    fontWeight: 'bold'
  }
}));

export default function CarTag({ car, hideUserTag, doNotLink }) {
  const classes = useStyles()

  let carName = car.name ? '#' + car.name : '';

  let carDetails = [];
  if (car.year) carDetails.push(car.year)
  if (car.make) carDetails.push(car.make.name)
  if (car.model) carDetails.push(car.model.name)
  if (car.trim) carDetails.push(car.trim.name)

  let owned = '';

  if(car.date_bought && car.date_sold) {
    owned = 'From ' + moment(car.date_bought).format('L') + ' through ' + moment(car.date_sold).format('L');
  } else if (car.date_bought) {
    owned = 'From ' + moment(car.date_bought).format('L') + ' through present';
  } else if (car.sold) {
    owned = 'No longer owned';
  } else {
    owned = 'Currently owned';
  }

  let userTag = null
  if ( !hideUserTag ) {
    userTag = (
      <p className={classes.description}>
        Driven by <Link to={"/" + car.user.username} className={classes.userLink}>@{car.user.username}</Link>
      </p>
    )
  }

  return (
    <div>
      <CarLink car={car} doNotLink={doNotLink}>
        <span className={classes.carLink}>{carDetails.join(" ")}</span>
      </CarLink>
      <p className={classes.description}>
        <span className={classes.carName}>{carName}</span> {owned}
      </p>
      { userTag }
    </div>
  )
}