import React from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(0.5, 2)
    },
  }),
);

export default function LoadingSubmitButton(props) {
  const classes = useStyles()
  const loading = props.loading ? true : false
  const spinner = props.loading ? <CircularProgress  size={16} className={classes.progress} /> : ''

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      disabled={loading}
      className={props.className}
      onClick={props.onClick}
    >
      {loading ? spinner : props.label}
    </Button>
  );
}