import React, { useState } from 'react'

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from "react-router-dom";
import { userService } from 'services/user/user.service'
import { userActions } from 'services/user/user.actions'
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: 'none !important',
    color: 'inherit'
  }
}));


function MobileHeader({ user, logout, history, children }) {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false)

  const auth = userService.auth.user();

  const logoutAndRedirect = () => {
    logout()
    setOpen(false)
    history.push('/')
  }

  let accountLinks = (
    <section>
      <Link to="/login" tabIndex="-1">
        <Button color="secondary">Login</Button>
      </Link>
      <Link to="/register" tabIndex="-1">
        <Button color="secondary">Signup</Button>
      </Link>
    </section>
  );

  if (auth) {
    accountLinks = (
      <section>
        <Link to={"/" + auth.username} tabIndex="-1">
          <Button color="secondary">My Garage</Button>
        </Link>
      </section>
    )
  }

  const drawerLinks = (
    <>
      <List component="nav" aria-label="main mailbox folders">
        <Link className={classes.link} to="/">
          <ListItem button>
            <ListItemIcon><Icon>home</Icon></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        
        <Link className={classes.link} to="/users">
          <ListItem button>
            <ListItemIcon><Icon>group</Icon></ListItemIcon>
            <ListItemText primary="Newest Users" />
          </ListItem>
        </Link>

        <Link className={classes.link} to="/cars">
          <ListItem button>
            <ListItemIcon><Icon>directions_car</Icon></ListItemIcon>
            <ListItemText primary="Newest Cars" />
          </ListItem>
        </Link>

        <Link className={classes.link} to="/mods">
          <ListItem button>
            <ListItemIcon><Icon>build</Icon></ListItemIcon>
            <ListItemText primary="Newest Mods" />
          </ListItem>
        </Link>
      </List>
    </>
  )

  const authDrawerLinks = !auth ? null : (
    <>
      <Divider />

      <List component="nav" aria-label="secondary mailbox folders">
        <Link className={classes.link} to={"/" + auth.username} tabIndex="-1">
          <ListItem button>
            <ListItemIcon><Icon>local_gas_station</Icon></ListItemIcon>
            <ListItemText primary="My Garage" />
          </ListItem>
        </Link>

        <Link className={classes.link} to={"/settings"} tabIndex="-1">
          <ListItem button>
            <ListItemIcon><Icon>settings_applications</Icon></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </Link>

        <ListItem button onClick={logoutAndRedirect}>
          <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>

      </List>
    </>
  )

  return (
    <div>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={() => setOpen(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            MyCarMods
          </Typography>

          {/* <Button color="inherit">Login</Button> */}
          { accountLinks }
        </Toolbar>

        <Drawer 
          className="media-drawer" 
          anchor="top" 
          open={open} 
          ModalProps={{ onBackdropClick: () => setOpen(false), onEscapeKeyDown: () => setOpen(false) }}
        >
          { drawerLinks }
          { authDrawerLinks }
        </Drawer>
        
      </AppBar>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user
});

const actionCreators = {
  logout: userActions.logout
};

export default connect(mapStateToProps, actionCreators)(withRouter(MobileHeader))