import { combineReducers } from 'redux';
import { user } from 'services/user/user.reducer';
import { alert } from 'services/alert/alert.reducer';

const rootReducer = combineReducers({
  user,
  alert
});

export default rootReducer;
