import React, { useState } from 'react'
import { Card, CardContent, CardMedia, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MediaManager from 'components/media/MediaManager'

import './userPhotoPreviewSelector.css'

const useStyles = makeStyles(theme => ({
  card: {
    // minHeight: '20em',
    position: 'relative'
  },
  coverPhoto: {
    height: 0,
    paddingTop: '30%'
  },
  profileBox: {
    position: 'absolute',
    bottom: '.5em',
    width: '24%'
    // width: '100%'
  },
  profilePhoto: {
    height: 0,
    paddingTop: '80%'
  }
}))

/**
 * 
 * @param object props { profile, cover, title, updateFn, finishFn, mediaFn }
 */
function UserPhotoPreviewSelector(props) {
  const classes = useStyles();

  const [ profile, setProfile ] = useState(props.profile)
  const [ cover, setCover ] = useState(props.cover)
  const [ selectorOpen, setSelectorOpen ] = useState(false)
  const [ property, setProperty ] = useState('')

  const openSelector = (property) => {
    setProperty(property)
    setSelectorOpen(true)
  }

  const updatePhoto = (media, stateUpdateFn) => {
    props.updateFn({ [property]: media.media.src })
      .then((data) => {
        if (property === cover.name) {
          setCover({ ...cover, src: media.media.src })
        } else if (property === profile.name) {
          setProfile({ ...profile, src: media.media.src})
        }

        props.finishFn(data) //userService.auth.set(data)
      })
  }

  const closeSelector = () => {
    setSelectorOpen(false)
  }

  return (
    <>
      <Card className={classes.card}>
        <Link className="image-link" onClick={() => openSelector(cover.name)}>
          <CardMedia
            className={classes.coverPhoto}
            image={cover.src}
            title="Cover Photo"
          />
        </Link>
        <CardContent style={{height: '10em'}}>
          <Card className={classes.profileBox}>
            <Link className="image-link" onClick={() => openSelector(profile.name)}>
              <CardMedia 
                className={classes.profilePhoto}
                image={profile.src}
                title="Profile Photo"
              />
            </Link>
            <CardContent style={{padding: '.5em 1em'}}>
              <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                @{props.title}
              </Typography>
            </CardContent>
          </Card>

          <Grid direction="row" container justify="flex-end">
            <Grid item xs={3} style={{position: 'relative'}}><Card className="dummy-card"></Card></Grid>
            <Grid item xs={3} style={{position: 'relative'}}><Card className="dummy-card"></Card></Grid>
            <Grid item xs={3} style={{position: 'relative'}}><Card className="dummy-card"></Card></Grid>
          </Grid>        
        </CardContent>
      </Card>

      <MediaManager 
        open={selectorOpen} 
        fetchMedia={() => props.mediaFn()}
        mediaItems={props.mediaItems}
        saveProps={props.saveProps}
        onSelect={(media) => updatePhoto(media) }
        closeFn={() => closeSelector(false)}></MediaManager>
    </>
  )
}

export default UserPhotoPreviewSelector