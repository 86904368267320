import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import AppsIcon from '@material-ui/icons/Apps';
import ViewDayIcon from '@material-ui/icons/ViewDay';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { prefsService } from 'services/prefs'

import OutlinedSelect from 'components/form/OutlinedSelect'
import ModCard from 'components/mod/ModCard'
import ModForm from 'components/mod/ModForm'

import { userService } from 'services/user/user.service'

const views = {
  LARGE: 'large',
  LIST: 'list',
  THUMBS: 'thumbs'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    welcome: {
      padding: '1em',
      fontSize: '2em'
    },
    viewButtons: {
      padding: theme.spacing(1, 0)
    },
    viewButton: {
      padding: theme.spacing(.8, 0)
    },
    filterInput: {
      // padding: '9px 14px'
    },
    newModButton: {
      marginTop: theme.spacing(1),
      width: '100%'
    }
  }),
);

function CarModList(props) {
  // const car = props.car
  const classes =  useStyles()

  const prefsView = prefsService.get('mod_view')
  const [ car, setCar ] = useState(props.car)
  const [ mod, setMod ] = useState({})
  const [ view, setView ] = useState(prefsView || views.LIST)
  const [ searchFilter, setSearchFilter ] = useState('')
  const [ categoryFilter, setCategoryFilter ] = useState([])

  const categories = car.mods
    .filter((e, i) => car.mods.findIndex(a => a.category_id === e.category_id) === i)
    .map((mod) => {
      return { id: mod.category.id, name: mod.category.name }
    });
  

  const updateViewOption = (option) => {
    setView(option)
    prefsService.set('mod_view', option)
  }

  const isViewActive = (option) => {
    return view === option ? 'primary' : ''
  }

  const filterBySearch = event => {
    const { value } = event.target
    setSearchFilter(value)
  }

  const updateCategoryFilter = (event) => {
    setCategoryFilter(event.target.value)
  }

  const noModsElement = 
  <Grid item xs={12}>
    <Paper className={classes.welcome}>
      {userService.auth.is(car.user_id) ? 
        <>
          <Typography component="h3" variant="h3" paragraph={true}>Hi there! You haven't added any mods yet!</Typography>
          <Typography component="p" paragraph={true}>Looks like you just added this vehicle to your garage. That's great! You can get started by using the "Edit your Vehicle" link in the profile picture. From there, add the details of your mod then you'll be prompted to add images and videos!</Typography>
        </>
      :
        <>
          <Typography component="h3" variant="h3" paragraph={true}>No mods here!</Typography>
          <Typography component="p" paragraph={true}>Check back later to see what's going to be added to this car!</Typography>
        </>
      }
    </Paper>
  </Grid>

  const modCards = !car.mods || !car.mods.length ? noModsElement : car.mods
    .filter((mod, i) => {
      let good = true

      // category filter
      if (categoryFilter.length) {
        good = categoryFilter.indexOf(mod.category_id) >= 0
      }

      // search filter
      if (good && searchFilter.length) {
        const text = [mod.title, mod.description, mod.category ? mod.category.name : ''].join(' ').toLowerCase()
        const tokens = searchFilter.split(/\s+/).filter((elem) => elem.length > 0)

        tokens.forEach(function (token) {
          if (text.indexOf(token) < 0) {
            good = false
          }
        })
      }

      return good
    })
    .map((mod, index) => <ModCard mod={mod} view={view} key={mod.id} isAuthOwned={userService.auth.is(car.user_id)}></ModCard>);

  return (
    <>
      <div>
        <Grid container spacing={1}>
          { userService.auth.is(car.user_id) ?
            <>
              <Grid item xs={12} sm={2}>
                <Button variant="contained" className={classes.newModButton} color="secondary" onClick={(event) => setCar({ ...car, addingMod: true})}>
                  Add a New Mod
                </Button>
              </Grid>

              <ModForm
                mod={{ ...mod, title: '', creating: true, car_id: car.id}} 
                open={car.addingMod} 
                onSave={(rsp) => { 
                  setCar({ ...car, addingMod: false, mods: [ ...car.mods, rsp ]})
                  setMod({})
                }}
                closeFn={() => setCar({ ...car, addingMod: false})}
              />
            </>
          :
          null
          }
          <Hidden smDown>
            <Grid item>
              <ButtonGroup aria-label="small button group" className={classes.viewButtons}>
                <Button className={classes.viewButton} color={isViewActive(views.LARGE)} onClick={() => updateViewOption(views.LARGE)}><ViewDayIcon/></Button>
                <Button className={classes.viewButton} color={isViewActive(views.LIST)} onClick={() => updateViewOption(views.LIST)}><ListIcon/></Button>
                <Button className={classes.viewButton} color={isViewActive(views.THUMBS)} onClick={() => updateViewOption(views.THUMBS)}><AppsIcon/></Button>
              </ButtonGroup>
            </Grid>
          </Hidden>

          <Grid item xs={12} md={4} lg={3}>
            <TextField
              fullWidth
              id="outlined-name"
              label="Filter mods by search..."
              name="searchFilter"
              onChange={filterBySearch}
              margin="dense"
              variant="outlined"
              className={classes.filterInput}
            />
          </Grid>

          <Hidden smDown>
            <Grid item>
              <OutlinedSelect 
                multiple={true} 
                values={categories}
                value={categoryFilter}
                handleChange={updateCategoryFilter} 
                label="Category"
                margin="dense"
                className={classes.filterInput}
              />
            </Grid>
          </Hidden>
        </Grid>
      </div>

      <Grid
        container
        direction="row"
        justify="flex-start"
        spacing={1}
      >
        { modCards }
      </Grid>
    </>
  )
}

// export default connect(mapStateToProps, actionCreators)(withRouter(LoginScene));

export default CarModList