import React from "react";
import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%',
    minWidth: 120
  },
  form: {
    display: 'inline'
  }
});

class OutlinedSelectComponent extends React.Component {
  state = {
    age: "",
    useLabelWidthFromRef: true,
    labelOffsetWidth: 0
  };

  handleCheckboxChange = event => {
    this.setState({ useLabelWidthFromRef: event.target.checked });
  };

  componentDidMount() {
    this.setState({
      labelOffsetWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  }

  render() {
    const { classes } = this.props;
    const { labelOffsetWidth, useLabelWidthFromRef } = this.state;
    const labelWidth = useLabelWidthFromRef ? labelOffsetWidth : 0;

    const menuItems = this.props.values ? this.props.values.map((item, index) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) : ''

    return (
      <>
        <FormControl variant="outlined" className={classes.formControl} style={this.props.margin === 'dense' ? { marginTop: '8px'} : {}}>
          <InputLabel
            margin={this.props.margin}
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="outlined-age-simple"
          >
            {this.props.label}
          </InputLabel>
          <Select
            fullWidth
            margin={this.props.margin}
            multiple={this.props.multiple ? true : false}
            value={this.props.value}
            onChange={this.props.handleChange}
            input={
              <OutlinedInput
                fullWidth
                labelWidth={labelWidth}
                name={this.props.id}
                margin={this.props.margin}
                id={this.props.id}
              />
            }
          >
            {menuItems}
          </Select>
        </FormControl>
      </>
    );
  }
}

const OutlinedSelect = withStyles(styles)(OutlinedSelectComponent);

export default OutlinedSelect
