import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Avatar from '@material-ui/core/Avatar'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography';

import LazyHero from 'react-lazy-hero'
import moment from 'moment'
import clsx from 'clsx';

import { userService } from 'services/user'

import { AppHeader } from 'components/header';
import CarForm from 'components/car/CarForm'
import CarCard from 'components/car/CarCard'
import StickyProfile from 'components/blocks/StickyProfile'
import UserCard from 'components/user/UserCard'

const useStyles = makeStyles((theme) =>
  createStyles({
    welcome: {
      padding: theme.spacing(1)
    },
    profileCard: {
      position: 'absolute',
      top: '-10em',
      left: 0,
      right: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        top: 0,
        marginTop: '-5em',
        marginBottom: '1em'
      },
    },
    carCards: {
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        marginTop: '-1em'
      },
    },
    paper: {
      position: 'relative',
      left: 0,
      right: 0,
      minHeight: '60em',
      // margin: theme.spacing(-5, 2, 5),
      padding: theme.spacing(1, 2),
      boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    },
    media: {
      height: 0,
      // paddingTop: '56.25%', // 16:9
      paddingTop: '76.25%', // not 16:9
    },
    leftNavItem: {
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1)
    },
    divider: {
      marginBottom: theme.spacing(1)
    }
  }),
);

export default function GarageScene({ match }) {
  const classes = useStyles();

  const [ user, setUser ] = useState({});

  useEffect(() => {
    if (user.id && match.params.username !== user.username) {
      setUser({});
    }

    if (user.id) {
      return
    }

    function fetchUser() {
      return userService.get(match.params.username)
        .then((data) => {
          setUser(data)
        })
    }  

    fetchUser()
  }, [user, match])

  const content = {
    loader: <div className={classes.loader} key="loader1"><LinearProgress /></div>,
    userHeader: null,
    authLinks: null,
    cars: null,
    main: ''
  }

  const authOwned = () => {
    const auth = userService.auth.user()
    return auth && auth.id === user.id
  }

  const addNewCar = (rsp) => {
    setUser({ ...user, addingNewCar: false, cars: [ ...user.cars, rsp ]})
  }

  const onDelete = (id) => {
    setUser({ ...user, cars: user.cars.filter(car => car.id !== id)})
  }

  const userMakes = !user.cars ? [] : user.cars.map(car => car.make.name)
    .filter((elem, index, arr) => arr.indexOf(elem) === index)
    .join(', ')

  if (user.id) {
    content.loader = ''

    if (user.cars.length) {
      content.cars = user.cars
        .filter((car) => !car.deleted_at)
        .map((car, index) => {
          return (
            <Grid item xs={12} sm={6} lg={4} key={car.id}>
              <CarCard car={car} onDelete={onDelete} hideUserTag={true} hideAuthLinks={true}></CarCard>
            </Grid>
          )
        });
    } else if (authOwned()) {
      content.cars = 
        <Grid item xs={12}>
          <Paper className={classes.welcome}>
            <Typography component="h3" paragraph="true" variant="h3">Welcome to your garage!</Typography>
            <Typography variant="p" component="p">You're on your way to cleanly and neatly documenting all the work you've done on your cars. Getting started is easy! Use the checklist below to create your profile and start adding cars!</Typography>
            <List>
              <ListItem button component="a" href="/settings">
                <ListItemText primary="Update your settings to add a profile and cover photo for your garages profile." />
              </ListItem>
              <ListItem button onClick={() => setUser({...user, addingNewCar: true})}>
                <ListItemText primary="Add your first car to your garage!" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
    } else {
      content.cars = 
        <Grid item xs={12} align="center">
          <Typography component="h3">{user.username} doesn't have any cars yet!</Typography>
          <Typography component="p">Check back later.</Typography>
        </Grid>
    }

    content.authLinks = !authOwned() ? null : (
      <>
        <Divider className={classes.divider} />
        
        <ListItem 
          button 
          className={clsx(classes.leftNavItem, 'auth-nav-link')} 
          onClick={() => setUser({...user, addingNewCar: true})}
          selected={false}
        >
          <ListItemIcon>
            <Icon>library_add</Icon>
          </ListItemIcon>
          <ListItemText primary="Add a New Car" />
        </ListItem>

        <CarForm
          car={{ date_bought: null, date_sold: null}}
          open={user.addingNewCar} 
          onSave={(rsp) => addNewCar(rsp)}
          closeFn={() => setUser({...user, addingNewCar: false})}
        />

      </>
    )

    content.userHeader = (
      <UserCard user={user} doNotLink={true}>
        <Divider/>

        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Icon>directions_car</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={ user.cars.length + ' vehicles in garage' } secondary={userMakes} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Icon>cake</Icon>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Member Since" secondary={moment(user.created_at).format('LL')} />
          </ListItem>
        </List>

        {content.authLinks}
      </UserCard>
    )
  }
  
  return (
    <AppHeader type="hero">
      <LazyHero
        imageSrc={user.cover_photo || '/img/defaults/cover-photo.jpg'}
        parallaxOffset={100}
        opacity={0}
        minHeight="50vh"
      ></LazyHero>
      <Paper className={classes.paper}>
        {content.loader}

        <StickyProfile profile={content.userHeader}>
          <Grid container spacing={1}>
            {content.cars}
          </Grid>
        </StickyProfile>
      </Paper>
      
    </AppHeader>
  );
}