import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import ModCategoryChip from './ModCategoryChip'
import ModRatingChip from './ModRatingChip'
import ModCostChip from './ModCostChip'
import ModInstallTimeChip from './ModInstallTimeChip'
import ModPowerGainChip from './ModPowerGainChip'
import ModWeightSavingsChip from './ModWeightSavingsChip'
import ModInstallationDateChip from './ModInstallationDateChip'

import ReadMore from 'components/util/ReadMore';
import MediaCarousel from 'components/util/MediaCarousel';


const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative'
  },
  media: {
    display: 'inline-block', 
    width: '35%',
    minHeight: '200px',
    float: 'left',
    margin: theme.spacing(0, 2, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  carousel: {
    minHeight: '200px'
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    borderRadius: '4px !important'
  },
  link: {
    cursor: 'pointer'
  },
  text: {
    margin: theme.spacing(1, 2)
  }
}));

export default function ModCardList({ mod, children }) {
  const classes = useStyles()

  const carousel = !mod.media || !mod.media.length ? null : (
    <div className={classes.media}>
      <MediaCarousel className={mod.carousel} media={mod.media}></MediaCarousel>
    </div>
  )

  return (
    <Grid item xs={12}>
      <Card square={true} className={classes.card}>
        {carousel}
        
        <div className={classes.text}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            {mod.title}
          </Typography>
          
          <ModCategoryChip size="small" category={mod.category} className={classes.chip} />
          <ModInstallationDateChip size="small" installDate={mod.install_date} removalDate={mod.removal_date} className={classes.chip} />
          <ModRatingChip size="small" rating={mod.happiness} className={classes.chip} />
          <ModCostChip size="small" cost={mod.price} className={classes.chip} />
          <ModInstallTimeChip size="small" min={mod.install_time} className={classes.chip} />
          <ModPowerGainChip size="small" gain={mod.hp_gains} className={classes.chip} />
          <ModWeightSavingsChip size="small" savings={mod.weight_savings} className={classes.chip} />

          <Typography variant="body1" component="div" className={classes.header}>
            <ReadMore text={mod.description } maxWords={80} />
            { children }
          </Typography>
        </div>
      </Card>
    </Grid>
  )
}