import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import YouTube from 'react-youtube'

const types = {
  YOUTUBE: 'youtube'
}

const useStyles = makeStyles(theme => ({
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function VideoChip({ video, className, standalone }) {
  const classes = useStyles()

  const content = {
    video: ''
  }

  const opts = {
    height: '100%',
    width: '100%',
  };

  const wrapperStyles = standalone ? {} : {position: 'relative', paddingTop: '100%'}

  if (video.source === types.YOUTUBE) {
    content.video = (
      <div style={wrapperStyles}>
        <YouTube
          videoId={video.video_id}
          id={video.video_id}
          opts={opts}
          className={classes.player}
          containerClassName={classes.className}
        />
      </div>
    )
  }

  return content.video
}