import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroller';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { AppHeader } from 'components/header';
import CarCard from 'components/car/CarCard'
import { carService } from 'services/car'

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      padding: theme.spacing(0, 2)
    },
    loader: {
      padding: theme.spacing(2, 0),
    }
  }),
);


export default function RecentCarsScene() {
  const classes = useStyles();
  
  const [ cars, setCars ] = useState([]);
  const [ hasMore, setHasMore ] = useState(true);

  function fetchCars(page) {
    // setHasMore(false)
    return carService.recent(page+1)
      .then((data) => {
        if (data.length === 0) {
          setHasMore(false)
          return
        }

        // setHasMore(true)
        setCars(cars.concat(data))
      })
  }

  useEffect(() => {
    if (cars.length) {
      return
    }

    carService.recent(1).then((data) => setCars(cars.concat(data)))
  }, [cars])

  

  const elements = cars.map((car, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={car.id}>
        <CarCard hideAuthLinks={true} car={car}></CarCard>
      </Grid>
    )
  });

  const loader = <div className={classes.loader} key="loader1"><LinearProgress /></div>
  
  return (
    <AppHeader>
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchCars}
        initialLoad={false}
        hasMore={hasMore}
        loader={loader}
      >
        <div className={classes.page}>
          <div className="flexGrow">
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={1}
            >
              {elements}
            </Grid>
          </div>
        </div>
      </InfiniteScroll>
    </AppHeader>
    
  );
}