import React from 'react'
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Button, Link as ToolbarLink, Toolbar, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { userService } from 'services/user/user.service'
import { userActions } from 'services/user/user.actions'

const useStyles = makeStyles(theme => ({
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  }
}));

function HeaderLinks({ user, logout, history, children, hero }) {
  const classes = useStyles();
  const auth = userService.auth.user();

  function logoutAndRedirect() {
    logout()
    history.push('/')
  }

  let accountLinks = (
    <section>
      <Link to="/login" tabIndex="-1">
        <Button color="secondary">Login</Button>
      </Link>
      <Link to="/register" tabIndex="-1">
        <Button color="secondary">Signup</Button>
      </Link>
    </section>
  );

  if (auth) {
    accountLinks = (
      <section>
        <Link to={"/" + auth.username} tabIndex="-1">
          <Button color="secondary">My Garage</Button>
        </Link>
        <Link to="/settings" tabIndex="-1">
          <Button color="secondary">Settings</Button>
        </Link>
        <ToolbarLink tabIndex="-1">
          <Button color="secondary" onClick={logoutAndRedirect}>Logout</Button>
        </ToolbarLink>
      </section>
    )
  }

  const headerElements = (
    <Toolbar className={classes.toolbar}>
      <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
        MyCarMods
      </Typography>
      <nav>
        <NavLink exact to="/" tabIndex="-1">
          <Button color="primary">Home</Button>
        </NavLink>
        <Link to="/cars" tabIndex="-1">
          <Button color="primary">Cars</Button>
        </Link>
        <Link to="/users" tabIndex="-1">
          <Button color="primary">Garages</Button>
        </Link>
        <Link to="/about" tabIndex="-1">
          <Button color="primary">About</Button>
        </Link>
        {accountLinks}
      </nav>
    </Toolbar>
  )
  
  return (headerElements)
}

const mapStateToProps = state => ({
  user: state.user
});

const actionCreators = {
  logout: userActions.logout
};

export default connect(mapStateToProps, actionCreators)(withRouter(HeaderLinks))