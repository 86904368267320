import React from 'react'
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '100%', // not 16:9
  },
  link: {
    cursor: 'pointer'
  }
}));

export default function ImageChip({image, onClick}) {
  const classes = useStyles()

  const media = <CardMedia className={classes.media} image={image.src} />

  if (onClick) {
    return <Link className={classes.link} onClick={() => onClick(image.src)}>{media}</Link>
  }

  return media
}