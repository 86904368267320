import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core';
import { AppHeader } from 'components/header';
import { OutlinedTextField, PasswordField, LoadingSubmitButton, FormError } from 'components/form'
import { makeStyles } from '@material-ui/core/styles';
import UserPhotoPreviewSelector from './components/UserPhotoPreviewSelector'
import { userService } from 'services/user'

const useStyles = makeStyles(theme => ({
  form: {
    // width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(5),
  },
  grid: {
    padding: theme.spacing(0, 1, 5)
  }
}));

export function UserSettingsScene(props) {
  const classes = useStyles();
  const auth = userService.auth.user()

  const initialUserForm = { first_name: auth.first_name, last_name: auth.last_name, email: auth.email, username: auth.username }
  const initialPasswordForm = { password: '', new_password: '', password_confirmation: '' }
  const [ user, setUser ] = useState(initialUserForm)
  const [ updatingUser, setUpdatingUser ] = useState(false)
  const [ userError, setUserError ] = useState('')
  const [ passwords, setPasswords ] = useState(initialPasswordForm)
  const [ updatingPassword, setUpdatingPassword ] = useState(false)
  const [ passwordError, setPasswordError ] = useState('')

  const handleUserDetailsChange = event => {
    const { name, value } = event.target
    setUser( { ...user, [name]: value})
    setUserError(null)
  }

  const handlePasswordChange = event => {
    const { name, value } = event.target
    setPasswords( { ...passwords, [name]: value})
  }

  const submitUserDetails = event => {
    event.preventDefault()

    setUpdatingUser(true)
    setUserError(null)

    userService.update(user)
      .then((data) => {
        setUser(data)
        userService.auth.set(data)
        setUpdatingUser(false)
      })
      .catch((error) => {

        setUpdatingUser(false)
        setUserError(error)
      })
  }

  const submitPassword = event => {
    event.preventDefault()

    setUpdatingPassword(true)
    setPasswordError(null)
    
    userService.changePassword(passwords)
      .then((data) => {
        setUpdatingPassword(false)
      })
      .catch((error) => {

        setUpdatingPassword(false)
        setPasswordError(error)
      })
  }

  return (
    <AppHeader>
      <Grid container className={classes.grid} justify="center" component="main">
        <Grid item xs={false} sm={12} md={8}>
          <Typography component="h1" variant="h5" className={classes.header}>
            Change Profile/Cover Photos
          </Typography>
          <Typography component="h6" variant="caption">
            Change the profile photo and cover photo used on your profile. Your profile photo will show up across the site, and your cover photo will be displayed at the top of your profile. Click each to pick a photo!
          </Typography>

          <UserPhotoPreviewSelector 
            profile={{name: 'profile_photo', src: auth.profile_photo || '/img/defaults/profile-photo.png'}}
            cover={{name: 'cover_photo', src: auth.cover_photo || '/img/defaults/cover-photo.jpg'}}
            title={auth.username}
            mediaFn={() => userService.getMedia('Image')}
            updateFn={userService.update}
            finishFn={(data) => userService.auth.set(data)}
          ></UserPhotoPreviewSelector>

          <Typography component="h1" variant="h5" className={classes.header}>
            Change Account Details
          </Typography>
          <Typography component="h6" variant="caption">
            Update account details like your name and email address.
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitUserDetails}>
            <OutlinedTextField required id="first_name" label="First Name" value={user.first_name} onChange={handleUserDetailsChange} autofocus />
            <OutlinedTextField required id="last_name" label="Last Name" value={user.last_name} onChange={handleUserDetailsChange} />
            <OutlinedTextField required id="email" label="Email Address" value={user.email} onChange={handleUserDetailsChange} />
            <OutlinedTextField required id="username" label="Username" value={user.username} onChange={handleUserDetailsChange} />
            <FormError error={userError}/>
            <LoadingSubmitButton label="Update User Details" className={classes.submit} onClick={submitUserDetails} loading={updatingUser} />
          </form>

          <Typography component="h1" variant="h5" className={classes.header}>
            Update your password
          </Typography>
          <Typography component="h6" variant="caption">
            Pick a new password to log into the site
          </Typography>
          <form className={classes.form} noValidate onSubmit={submitPassword}>
            <PasswordField id="password" label="Current Password" onChange={handlePasswordChange} />
            <PasswordField id="new_password" label="New Password" onChange={handlePasswordChange} />
            <PasswordField id="password_confirmation" label="Confirm Password" onChange={handlePasswordChange} />
            <FormError error={passwordError}/>
            <LoadingSubmitButton label="Update Password" className={classes.submit} onClick={submitPassword} loading={updatingPassword} />
          </form>
        </Grid>
      </Grid>
    </AppHeader>
  )
}