import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { OutlinedTextField, PasswordField, CheckboxField, LoadingSubmitButton } from 'components/form'
import { Copyright } from 'components'
import { userActions } from 'services/user'
import { AppHeader } from 'components/header'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/img/s3-3.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginScene(props) {
  const classes = useStyles();
  const initialFormState = { email: '', password: '' }
  const [user, setUser] = useState(initialFormState)

  useEffect(() => {
    if (props.user && props.user.id) {
      props.history.push('/' + props.user.username)
    }
  }, [props])

  const handleChange = event => {
    const { name, value } = event.target
    setUser( { ...user, [name]: value})
  }

  const handleSubmit = event => {
    event.preventDefault()
    props.login(user.email, user.password)
  }

  return (
    <AppHeader type="fixed">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <OutlinedTextField required id="email" label="Email Address or Username"  onChange={handleChange} autofocus />
              <PasswordField id="password" label="Password"  onChange={handleChange} />
              <CheckboxField id="remember" label="Remember me" />
              <LoadingSubmitButton label="Login" className={classes.submit} onClick={handleSubmit} loading={props.user.loggingIn} />

              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </AppHeader>
  );
}

const mapStateToProps = state => ({
  user: state.user
});

const actionCreators = {
  login: userActions.login
}

export default connect(mapStateToProps, actionCreators)(withRouter(LoginScene));