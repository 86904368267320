import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from "@material-ui/pickers";
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';

import axios from 'axios'

import authHeader from 'helpers/authHeader'
import config from 'helpers/config'

import { modService } from 'services/mod/mod.service'

import OutlinedTextField from 'components/form/OutlinedTextField'
import OutlinedSelect from 'components/form/OutlinedSelect'
import LoadingSubmitButton from 'components/form/LoadingSubmitButton'
import FormError from 'components/form/FormError'

import moment from 'moment'

const useStyles = makeStyles(theme => ({
  closeLink: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer'
  },
  mediaGrid: {
    margin: theme.spacing(1, 0)
  },
  picker: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}))

export default function ModForm (props) {
  
  const open = props.open
  const closeFn = props.closeFn
  const onSave = props.onSave
  const classes = useStyles()

  let requiredFields = { removal_date: null, install_date: null }
  
  // let fixedPropMod = props.mod
  // props.mod.removal_date = props.mod.removal_date ? props.mod.removal_date : null
  
  // set state for media
  const [ mod, setMod ] = useState(Object.assign({}, requiredFields, props.mod))
  const [ categories, setCategories ] = useState([])
  const [ error, setError ] = useState('')

  useEffect(() => {
    const ready = props.mod.editing || props.mod.creating

    if (!ready || categories.length) {
      return
    }

    function fetchCategories(car) {
      return modService.categories()
        .then((data) => {
          setCategories(data)
        })
    }  

    fetchCategories()
  }, [props.mod, categories.length])

  const categoryList = categories.map((category) => {
    return { id: category.name, name: category.name }
  });

  const handleChange = (event) => {
    setError('')
    const { name, value } = event.target
    setMod( { ...mod, [name]: value})
  }

  const happinessScale = (value) => {
    return value + '/10'
  }

  const saveMod = (event, close) => {
    event.preventDefault()
    setMod({ ...mod, saving: true })
    setError('')

    if (mod.id) {
      axios.put(config.authApi('/mods/' + mod.id), { mod }, { 
        headers: authHeader()
      }).then(response => {
        setMod({ ...mod, saving: false })
        onSave(response.data, true)
      }).catch(error => {
        setError(error.response.data.message)
        setMod({ ...mod, saving: false })
      })
    } else {
      axios.post(config.authApi('/mods'), { mod }, {
        headers: authHeader()
      }).then(response => {
        setMod({ ...mod, saving: false })
        onSave({ ...response.data, managingMedia: true})
      }).catch(error => {
        setError(error.response.data.message)
        setMod({ ...mod, saving: false })
      })
    }
    
  }

  const modCategory = mod.category ? mod.category.name || mod.category || null : null

  return (
    <Drawer 
      className="media-drawer" 
      anchor="bottom" 
      open={open} 
      ModalProps={{ onBackdropClick: closeFn, onEscapeKeyDown: closeFn }}
    >
      <CloseIcon onClick={closeFn} className={classes.closeLink}></CloseIcon>
      <Typography component="h1" variant="h5" className={classes.header}>
        { mod.id ? 'Edit Mod Details' : 'Add a New Mod' }
      </Typography>

      <form className={classes.form} noValidate onSubmit={saveMod}>
        <Grid container spacing={1} justify="center">
          <Grid item xs={12} sm={12} md={8}>
            <OutlinedTextField required id="title" label="Mod Name" value={mod.title} onChange={handleChange} first={true} />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <OutlinedSelect 
              id="category"
              // multiple={true} 
              fullWidth
              values={categoryList}
              value={modCategory}
              handleChange={handleChange} 
              label="Category"
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={2}>
            <DatePicker
              className={classes.picker}
              inputVariant="outlined"
              variant="dialog"
              format="L"
              label="Date Installed"
              autoOk
              clearable={true}
              value={mod.install_date}
              onChange={(install_date) => setMod({...mod, install_date: install_date ? moment(install_date).format('YYYY-MM-DD') : null})}
              animateYearScrolling
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={2}>
            <DatePicker
              className={classes.picker}
              inputVariant="outlined"
              variant="dialog"
              format="L"
              label="Date Removed"
              autoOk
              clearable={true}
              value={mod.removal_date}
              onChange={(removal_date) => setMod({...mod, removal_date: removal_date ? moment(removal_date).format('YYYY-MM-DD') : null})}
              animateYearScrolling
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <OutlinedTextField id="install_time" label="Time to Install (min)" value={mod.install_time} onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <OutlinedTextField id="price" label="Price (USD)" value={mod.price} onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <OutlinedTextField id="weight_savings" label="Weight Savings (lbs)" value={mod.weight_savings} onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <OutlinedTextField id="hp_gains" label="Power Gains (hp)" value={mod.hp_gains || ''} onChange={handleChange} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1} justify="center">
              <Grid item xs={3} sm={2} md={1}>
                <Typography id="happiness-label" align="right" style={{paddingRight: '1em'}} gutterBottom>
                  Happiness
                </Typography>
              </Grid>

              <Grid item xs={9} sm={10} md={11}>
                <Slider 
                  id="happiness"
                  aria-labelledby="happiness-label" 
                  getAriaValueText={happinessScale}
                  valueLabelFormat={happinessScale}
                  valueLabelDisplay="auto" 
                  step={1} 
                  min={0} 
                  max={10} 
                  marks
                  value={mod.happiness} 
                  onChange={(event, newValue) => handleChange({target: {name: 'happiness', value: newValue}})} />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <OutlinedTextField id="description" label="Description" placeholder="What are your thoughts on this mod? Strengths? Weaknesses? Review it and let people know what you think" value={mod.description} multiline onChange={handleChange} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormError error={error}/>
            <LoadingSubmitButton label="Save Mod" className={classes.submit} onClick={(event) => saveMod(event)} loading={mod.saving} />
          </Grid>
        </Grid>
      </form>
    </Drawer>
  )
}
