import React from 'react'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography';

import UserLink from 'components/user/UserLink'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative'
  },
  media: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '76.25%', // not 16:9
  },
  cardContent: {
    paddingBottom: 0
  },
  edit: {
    position: 'absolute',
    top: '.5em',
    right: '.5em'
  }
}));


export default function UserCard({ user, content, children, isExpanded, doNotLink }) {
  const classes = useStyles();

  return (
    <Card className={clsx('garage-profile-header', classes.profileCard)}>
      <UserLink user={user} doNotLink={doNotLink}>
        <CardMedia
          className={classes.media}
          image={user.profile_photo || '/img/defaults/profile-photo.png'}
          title={user.username}

          alt=""
        />
      </UserLink>
      
      <CardContent>
        <UserLink user={user} doNotLink={doNotLink}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            @{user.username}
          </Typography>
        </UserLink>

        {children}
      </CardContent>
    </Card>
  )
}