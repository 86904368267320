import React, { useState, useCallback, useMemo } from 'react'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import { TextFieldWithButton } from 'components/form'
import config from 'helpers/config'
import authHeader from 'helpers/authHeader'

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1)
  },
  hidden: {
    display: 'none'
  },
  dndText: {
    margin: '16px 0'
  }
}))

export default function MediaUploader ({ onSuccess, saveProps, imagesOnly }) {
  const classes = useStyles()
  const [ loading, setLoading ] = useState(false)
  const [ videoUrl, setVideoUrl ] = useState('')
  const [ addingVideo, setAddingVideo ] = useState(false)

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',
    marginTop: '16px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const onDrop = useCallback(acceptedFiles => {
    setLoading(true)

    const upload = (file) => {
      // uuuuugggghhhhhh php
      let data = new FormData()
      data.append('file', file)
      if (typeof saveProps === 'object') {
        Object.keys(saveProps).forEach(function(key) {
          data.append(key, saveProps[key])
        })
      }

      fetch(config.authApi('/media'), { // Your POST endpoint
        method: 'POST',
        headers: { ...authHeader() },
        body: data // This is your file object
      }).then((response) => response.json() // if the response is a JSON object
      ).then((success) => {
        setLoading(false)
        onSuccess(success)
      }).catch((error) => {
        setLoading(false)
      });
    };
    
    acceptedFiles.forEach((file) => {
      upload(file)
    })
  }, [onSuccess, saveProps])

  const uploadVideo = () => {
    setAddingVideo(true)
    axios.post(config.authApi('/media'), { ...saveProps, video: videoUrl }, { 
      headers: authHeader()
    }).then(response => response.data)
    .then(success => {
      onSuccess(success)
      setAddingVideo(false)
      setVideoUrl('')
    }).catch(response => {
      setAddingVideo(false)
    })

  }
  
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: 'image/*', onDrop: onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragAccept,
    isDragActive,
    isDragReject,
    rejectStyle, 
    acceptStyle,
    activeStyle,
    baseStyle
  ]);
  
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})


  return (
    <Grid container className={classes.wrapper} spacing={1}>
      <Grid item xs={12} sm={imagesOnly ? 12 : 6} className={loading ? classes.hidden : null}>
        <div {...getRootProps({style})}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p className={classes.dndText}>Drop images here...</p> :
              <p className={classes.dndText}>Drag images here or click to upload images</p>
          }
        </div>
      </Grid>
      { !imagesOnly ? 
        <Grid item xs={12} sm={6} className={loading ? classes.hidden : null}>
          <TextFieldWithButton 
            className={classes.videoLoader}
            id="videoUrl" 
            value={videoUrl} 
            label="Enter a video URL to add"
            buttonLabel="Add"
            onChange={(event) => setVideoUrl(event.target.value)} 
            onSubmit={(event) => uploadVideo(event, true)}
            loading={addingVideo}
          />
        </Grid>
      : null}
      
      { loading ?
        <Grid item xs={12} style={{height: '92px'}}>
          <div style={{marginTop: '46px'}} key="loader1"><LinearProgress /></div>
        </Grid> 
      : null }
    </Grid>
  )
}