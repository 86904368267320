import React, { useState, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import LazyHero from 'react-lazy-hero'
import clsx from 'clsx';

import { carService } from 'services/car'

import { AppHeader } from 'components/header';
import CarCard from 'components/car/CarCard'
import StickyProfile from 'components/blocks/StickyProfile'

import CarMediaGrid from './components/CarMediaGrid'
import CarAbout from './components/CarAbout'
import CarModList from './components/CarModList'




const useStyles = makeStyles((theme) =>
  createStyles({
    nav: {
      paddingTop: theme.spacing(2)
    },
    header: {
      marginBottom: theme.spacing(1),
    },
    carName: {
      fontStyle: 'italic',
      color: theme.palette.primary.light
    },
    profileCard: {
      position: 'absolute',
      top: '-10em',
      height: '100%',
      left: 0,
      right: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        top: 0,
        marginTop: '-5em',
        marginBottom: '1em'
      },
    },
    media: {
      height: 0,
      // paddingTop: '56.25%', // 16:9
      paddingTop: '76.25%', // not 16:9
    },
    paper: {
      position: 'relative',
      left: 0,
      right: 0,
      minHeight: '60em',
      margin: theme.spacing(-5, 2, 5),
      padding: theme.spacing(1, 2),
      boxShadow: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1, 1),
        margin: theme.spacing(-5, 0, 5),
      },
    },
    headerNav: {
      marginBottom: theme.spacing(1),
      zIndex: 10000
    },
    leftNavItem: {
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1)
    },
    divider: {
      marginBottom: theme.spacing(1)
    }
  }),
);

const sections = {
  MODS: 'mods',
  ABOUT: 'about',
  MEDIA: 'media'
}

export default function CarScene({ match }) {
  const classes = useStyles();

  const [ car, setCar ] = useState({});
  const [ mod, setMod ] = useState({});
  const [ section, setSection ] = useState(match.params.section || 'mods')

  useEffect(() => {
    if (car.id) {
      return
    }

    function fetchCar(car) {
      return carService.get(match.params.username, match.params.car)
        .then((data) => {
          setCar(data)
        })
    }  

    fetchCar()
  }, [car, mod, match])

  function isSelected(linkSection) {
    return linkSection === section
  }

  const content = {
    loader: <div className={classes.loader} key="loader1"><LinearProgress /></div>,
    carType: '',
    carName: '',
    main: ''
  }

  if (car.id) {
    content.loader = ''
    content.carType = (<span>{car.year} {car.make ? car.make.name : ''} {car.model ? car.model.name : ''} {car.trim ? car.trim.name : ''}</span>)
    content.carName = car.name ? <span className={classes.carName}>{car.name}</span> : ''
  }

  // determine which section component to display
  if (car.id) {
    if (isSelected(sections.MEDIA)) {
      content.main = (<CarMediaGrid car={car}></CarMediaGrid>)
    } else if (isSelected(sections.MODS)) {
      content.main = (<CarModList car={car} openEditForm={setMod}></CarModList>)
    } else if (isSelected(sections.ABOUT)) {
      content.main = (<CarAbout car={car}></CarAbout>)
    }
  }

  const profileCard = car.id ? <CarCard car={car} setCar={setCar} doNotLink={true}></CarCard> : <></>;
  
  return (
    <AppHeader type="hero">
      <LazyHero
        imageSrc={car.cover_photo || '/img/defaults/cover-photo.jpg'}
        parallaxOffset={100}
        opacity={0}
        minHeight="70vh"
      ></LazyHero>

      <Paper className={classes.paper}>
        { car.id ? null : <div className={classes.loader} key="loader1"><LinearProgress /></div> }

        <StickyProfile profile={profileCard}>
          { car.id ?
            <ButtonGroup color="primary" className={clsx('', classes.headerNav)} fullWidth aria-label="full width outlined button group">
              <Button variant={isSelected(sections.MODS) ? "contained" : ""} onClick={event => setSection(sections.MODS)}>Mods</Button>
              <Button variant={isSelected(sections.MEDIA) ? "contained" : ""} onClick={event => setSection(sections.MEDIA)}>Media</Button>
              <Button variant={isSelected(sections.ABOUT) ? "contained" : ""} onClick={event => setSection(sections.ABOUT)}>About</Button>
            </ButtonGroup> : null }

          { content.main }
        </StickyProfile>
      </Paper>
      
    </AppHeader>
  );
}