import React from 'react'
import Chip from '@material-ui/core/Chip';

export default function ModWeightSavingsChip({savings, className}) {
  if (!savings) {
    return null
  }

  if (savings > 0) {
    const label = '-' + savings + ' lbs'
    return <Chip size="small" label={label} className={className} />
  }

  const label = '+' + savings + ' lbs'
  return <Chip size="small" label={label} className={className} />
}