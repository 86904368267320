import React, { useState } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Lightbox from 'react-image-lightbox';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import MediaChip from 'components/media/MediaChip'

import { userService } from 'services/user/user.service'

// @todo this is duplicated somewhere else. compartmentalize
const types = {
  IMAGE: 'App\\Models\\Image'
}

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      background: 'white',
      paddingTop: theme.spacing(1),
      zIndex: 1
    },
    viewButtons: {
      padding: theme.spacing(1, 0, 0.5, 0.5),
      height: theme.spacing(6.4)
    },
    viewSearch: {
      marginRight: theme.spacing(0)
    },
    search: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(1),
      },
    },
    welcome: {
      padding: '1em',
      fontSize: '2em'
    }
  }),
);

export default function CarMediaGrid(props) {
  const car = props.car
  const classes =  useStyles()

  const [ modalOpen, setModalOpen ] = useState(false)
  const [ modalIndex, setModalIndex ] = useState(0)

  if (!car.media || !car.media.length) {
    return (
      <Grid item xs={12}>
        <Paper className={classes.welcome}>
          {userService.auth.is(car.user_id) ? 
            <>
              <Typography component="h3" variant="h3" paragraph={true}>Hi there! You haven't added any media yet!</Typography>
              <Typography component="p" paragraph={true}>Looks like you just added this vehicle to your garage. You can add photos to show off your car by using the "Edit your Vehicle" button in your cars profile picture.</Typography>
            </>
          :
            <>
              <Typography component="h3" variant="h3" paragraph={true}>No mods here!</Typography>
              <Typography component="p" paragraph={true}>Check back later to see what's going to be added to this car!</Typography>
            </>
          }
        </Paper>
      </Grid>
    )
  }

  // define the list of photos we have to work with
  const images = car.media
    .filter(media => media.media_type === types.IMAGE)
    .map(media => media.media.src)

  function lightboxTrigger(src) {
    let index = images.indexOf(src)
    
    if (index < 0 || index > images.length) {
      index = 0
    }

    setModalIndex(index)
    setModalOpen(true)
  }

  function closeLightbox() {
    setModalOpen(false)
  }
  
  const chips = car.media.map((media, i) => {
    return (<MediaChip media={media} key={media.id} onClick={(src) => lightboxTrigger(src)}/>)
  })

  return (
    <div>
      { modalOpen && <Lightbox
        mainSrc={images[modalIndex]}
        nextSrc={images[(modalIndex + 1) % images.length]}
        prevSrc={images[(modalIndex + images.length - 1) % images.length]}
        onCloseRequest={() => closeLightbox()}
        onMovePrevRequest={() => setModalIndex((modalIndex + images.length - 1) % images.length)}
        onMoveNextRequest={() => setModalIndex((modalIndex + 1) % images.length)}
      />}

      <Grid
        container
        direction="row"
        justify="flex-start"
        spacing={1}
      >
        { chips }
      </Grid>
    </div>
  )
} 