import React from 'react'
import Chip from '@material-ui/core/Chip';

export default function ModCostChip({cost, className}) {
  if (!cost) {
    return null
  }
  
  const label = '$' + cost + ' USD'
  return <Chip size="small" label={label} className={className} />
}