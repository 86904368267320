import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid';
import InfiniteScroll from 'react-infinite-scroller';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { userService } from 'services/user'
import { AppHeader } from 'components/header';

import UserCard from 'components/user/UserCard'

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      padding: theme.spacing(0, 2)
    },
    loader: {
      padding: theme.spacing(2, 0),
    }  }),
);


export default function RecentUsersScene() {
  const classes = useStyles();
  
  const [ users, setUsers ] = useState([]);
  const [ hasMore, setHasMore ] = useState(true);

  function fetchUsers(page) {
    return userService.recent(page+1)
      .then((data) => {
        if (data.length === 0) {
          setHasMore(false)
          return
        }

        setUsers(users.concat(data))
      })
  }

  useEffect(() => {
    if (users.length) {
      return
    }

    userService.recent(1).then((data) => setUsers(users.concat(data)))
  }, [users])


  const elements = users.map((user, index) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={user.id}>
        <UserCard user={user}></UserCard>
      </Grid>
    )
  });

  const loader = <div className={classes.loader} key="loader1"><LinearProgress /></div>
  
  return (
    <AppHeader>
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchUsers}
        initialLoad={false}
        hasMore={hasMore}
        loader={loader}
      >
        <div className={classes.page}>
          <div className="flexGrow">
            <Grid
              container
              direction="row"
              justify="flex-start"
              spacing={1}
            >
              {elements}
            </Grid>
          </div>
        </div>
      </InfiniteScroll>
    </AppHeader>
  );
}