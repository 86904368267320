import React from 'react'
import { connect } from 'react-redux';

import history from 'helpers/history';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';

import { alertActions } from 'services/alert/alert.actions';

const useStyles = makeStyles((theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    success: {
      backgroundColor: green[600],
    }
  }),
);

function AppToast({alert, clearAlerts}) {
  const classes = useStyles()

  const message = (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={60000}
      open={alert.message ? true : false}
      onClose={clearAlerts}
    >
      <SnackbarContent
        className={classes[alert.type]}
        aria-describedby="client-snackbar"
        message={<span id="client-snackbar">{alert.message}</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={clearAlerts}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
    
    
  history.listen((location, action) => {
    // clear alert on location change
    clearAlerts();
  });

  return <div>{message}</div>

}
const mapStateToProps = state => ({
  alert: state.alert
});

const actionCreators = {
  clearAlerts: alertActions.clear
};

// const connectedApp = connect(mapState, actionCreators)(App);
export default connect(mapStateToProps, actionCreators)(AppToast);