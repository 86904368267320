import React from 'react'
import { Link } from "react-router-dom";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none'
    }
  }),
);

export default function UserLink({user, className, doNotLink, children}) {
  const classes = useStyles()

  if (doNotLink) {
    return children
  }
  
  let link = '/' + user.username
  
  return (
    <Link to={link} className={clsx(className, classes.link)}>
      { children }
    </Link>
  )
}