import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function CheckboxField(props) {
  return (
    <FormControlLabel
      control={<Checkbox value={props.id} color="primary" />}
      label={props.label}
    />
  );
}